import React, {useEffect, useState} from 'react';
import './App.css';
import * as StellarSdk from 'stellar-sdk';
window.Buffer = window.Buffer || require("buffer").Buffer; 

function App() {
  const server = new StellarSdk.Server("https://horizon.stellar.org");
  const [loading, setLoading] = useState(false);
  const [txHistory, setTxHistory] = useState([]);
  const [nextHistory, setNextHistory] = useState(null);
  const [isFullLoaded, setIsFullLoaded] = useState(false);
  const [gAddress, setGAddress] = useState('');
  const [memo, setMemo] = useState('');
  const [startTrigger, setStartTrigger] = useState(0);
  const [errorText, setErrorText] = useState('');
  const [emptyPageCount, setEmptyPageCount] = useState(0);
  // const [totalXLM, setTotalXLM] = useState(0);
  const [endDateTime, setEndDateTime] = useState('');
  const [refundHistory, setRefundHistory] = useState([]);

  // const [year, setYear] = useState('');
  // const [month, setMonth] = useState('');
  // const [day, setDay] = useState('');
  // const [hour, setHour] = useState(0);
  // const [minute, setMinute] = useState(0);
  // const [second, setSecond] = useState(0);

  // const []
  

  const loadMoreTransactionsHistory = async () => {
    if (!nextHistory || isFullLoaded || loading) {
        return;
    }
    setLoading(true)
    nextHistory.then(result => {
        let records = result.records.filter(record => record.memo?.normalize().toUpperCase() === memo.normalize().toUpperCase() || record.memo?.normalize().toUpperCase() === (memo.normalize() + ' ').toUpperCase())

        records.forEach((record, index) => {
          let env_xdr = StellarSdk.xdr.TransactionEnvelope.fromXDR(
            record.envelope_xdr,
            'base64',
          )
          let xlmAmount = env_xdr._value._attributes.tx._attributes.operations[0]._attributes.body._value._attributes.amount / 10000000
          
          // if (xlmAmount < 10) {
          //   records.splice(index, 1)
          // } else {
          Object.assign(record, {xlmAmount: (~~xlmAmount).toString()})
          // }
        })
        records = records.reduce((acc, item) => {
          if (Number(item.xlmAmount) >= 10) {
              acc.push(item);
          }
          return acc;
        }, []);

        let refTxHistory = []
        if (endDateTime) {
          records = records.reduce((acc, record) => {
            if (new Date(record.created_at.slice(0, -1)).getTime() <= new Date(endDateTime).getTime()) {
                acc.push(record);
            } else {
              refTxHistory.push(record)
            }
            return acc;
          }, []);
        }
        if (refTxHistory.length > 0) {
          setRefundHistory([...refundHistory, ...refTxHistory])
        }

        setTxHistory([...txHistory, ...records])
        // this.paymentsHistory = [...this.paymentsHistory, ...result.records];
        setNextHistory(result.next);
        setIsFullLoaded(result.records.length === 0);

        setEmptyPageCount(value => value + 1)
        // console.log(emptyPageCount)
        setLoading(false);
        // console.log(records)
        // record.created_at = "2023-10-20T16:06:20Z"

    });
  }
  const getTransactions = async () => {
    return server.transactions()
        .forAccount(gAddress)
        .limit(200)
        .order('desc')
        .call();
  }
  
  useEffect(() => {
    if (startTrigger > 0) {
      setIsFullLoaded(false)
      setNextHistory(null)
      setLoading(true)
      getTransactions().then(result => {
        let records = result.records.filter(record => record.memo?.normalize().toUpperCase() === memo.normalize().toUpperCase() || record.memo?.normalize().toUpperCase() === (memo.normalize() + ' ').toUpperCase())
        
        // "envelope_xdr": "AAAAAgAAAAAPwh2zLwyXGUvkzv4RR/AHuK2g6e0q5JpR7ASkfFhMBAABhqACZd40AABDYQAAAAAAAAABAAAACEpFQkVMQUxJAAAAAQAAAAEAAAAAD8Idsy8MlxlL5M7+EUfwB7itoOntKuSaUewEpHxYTAQAAAABAAAAAAsAiBxMmqNCnYL7F6FHR1bnqpqEqx+bgI7T8PR133NLAAAAAAAAAAA7msoAAAAAAAAAAAF8WEwEAAAAQOLz3+c/f+WnpOLYSafz6by1/fByCRIjfjhXiC22t24tOCVYZPRX9sL5FBYHwupyb/CFHIzD12GkrH0+NgyvtgE=",
        // "result_xdr": "AAAAAAAAA+gAAAAAAAAAAQAAAAAAAAABAAAAAAAAAAA=",
        // "result_meta_xdr": "AAAAAgAAAAIAAAADAq/mUAAAAAAAAAAAD8Idsy8MlxlL5M7+EUfwB7itoOntKuSaUewEpHxYTAQAAAABtKcGgAJl3jQAAENgAAAD5wAAAAEAAAAAxHHGQ3BiyVBqiTQuU4oa2kBNL0HPHTolX0Mh98bg4XUAAAAAAAAACWxvYnN0ci5jbwAAAAEAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAwAAAAACr+YgAAAAAGP3rrcAAAAAAAAAAQKv5lAAAAAAAAAAAA/CHbMvDJcZS+TO/hFH8Ae4raDp7SrkmlHsBKR8WEwEAAAAAbSnBoACZd40AABDYQAAA+cAAAABAAAAAMRxxkNwYslQaok0LlOKGtpATS9Bzx06JV9DIffG4OF1AAAAAAAAAAlsb2JzdHIuY28AAAABAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAAAAAAAAMAAAAAAq/mUAAAAABj96/LAAAAAAAAAAEAAAAEAAAAAwKv5lAAAAAAAAAAAA/CHbMvDJcZS+TO/hFH8Ae4raDp7SrkmlHsBKR8WEwEAAAAAbSnBoACZd40AABDYQAAA+cAAAABAAAAAMRxxkNwYslQaok0LlOKGtpATS9Bzx06JV9DIffG4OF1AAAAAAAAAAlsb2JzdHIuY28AAAABAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAAAAAAAAMAAAAAAq/mUAAAAABj96/LAAAAAAAAAAECr+ZQAAAAAAAAAAAPwh2zLwyXGUvkzv4RR/AHuK2g6e0q5JpR7ASkfFhMBAAAAAF5DDyAAmXeNAAAQ2EAAAPnAAAAAQAAAADEccZDcGLJUGqJNC5TihraQE0vQc8dOiVfQyH3xuDhdQAAAAAAAAAJbG9ic3RyLmNvAAAAAQAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAAAAAAAAAAAAAAAAAADAAAAAAKv5lAAAAAAY/evywAAAAAAAAADAq/lkAAAAAAAAAAACwCIHEyao0KdgvsXoUdHVueqmoSrH5uAjtPw9HXfc0sAAAAArg7x2AKsgWkAAAAzAAAAAgAAAAEAAAAAxHHGQ3BiyVBqiTQuU4oa2kBNL0HPHTolX0Mh98bg4XUAAAAAAAAACWxvYnN0ci5jbwAAAAEAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAwAAAAACr+KUAAAAAGP3mjMAAAAAAAAAAQKv5lAAAAAAAAAAAAsAiBxMmqNCnYL7F6FHR1bnqpqEqx+bgI7T8PR133NLAAAAAOmpu9gCrIFpAAAAMwAAAAIAAAABAAAAAMRxxkNwYslQaok0LlOKGtpATS9Bzx06JV9DIffG4OF1AAAAAAAAAAlsb2JzdHIuY28AAAABAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAAAAAAAAMAAAAAAq/ilAAAAABj95ozAAAAAAAAAAA=",
        // "fee_meta_xdr"

        records.forEach((record, index) => {
          let env_xdr = StellarSdk.xdr.TransactionEnvelope.fromXDR(
            record.envelope_xdr,
            'base64',
          )
          // if (env_xdr._value._attributes.tx._attributes.operations[0]._attributes.body._value._attributes.amount / 10000000 )
          let xlmAmount = env_xdr._value._attributes.tx._attributes.operations[0]._attributes.body._value._attributes.amount / 10000000

          // if (xlmAmount < 10) {
          //   records.splice(index, 1)
          // } else {
          Object.assign(record, {xlmAmount: (~~xlmAmount).toString()})
          // }
        })
        
        records = records.reduce((acc, item) => {
          if (Number(item.xlmAmount) >= 10) {
              acc.push(item);
          }
          return acc;
        }, []);
        // new Date(e.target.value).getTime()
        let refTxHistory = []
        if (endDateTime) {
          records = records.reduce((acc, record) => {
            if (new Date(record.created_at.slice(0, -1)).getTime() <= new Date(endDateTime).getTime()) {
                acc.push(record);
            } else {
              refTxHistory.push(record)
            }
            return acc;
          }, []);
        }
        if (refTxHistory.length > 0) {
          setRefundHistory(refTxHistory)
        }
        // console.log(records)
        setTxHistory(records);
        setNextHistory(result.next)
        setLoading(false)
      })
    }
  }, [startTrigger])

  useEffect(() => {
    if (startTrigger > 0 && nextHistory) {
      loadMoreTransactionsHistory().then(result => {
        // console.log(result)
      })
    }
  }, [nextHistory])

  // useEffect(() => {
  //   console.log(txHistory)
  // }, [txHistory])

  const startFetch = () => {
    if (!memo || !gAddress) {
      setErrorText("Address & Memo are required")
      return;
    } else {
      setErrorText('')
    }
    setStartTrigger(0)
    setStartTrigger(value => value + 1)
    setEmptyPageCount(0)
    setRefundHistory([])
  }

  const stopFetch = () => {
    setStartTrigger(0)
  }

  // console.log(txHistory)
  return (
    <div className="App">
      <div className="App-header"  style={{paddingBottom:"50px"}}> 
        <div>
          <p className='status'>Simple tool to fetch transactions from a specific address with a specific memo.</p>
          <p className='status'>Insert Address and Memo, press <b>Start</b> and wait for results. If address has a lot of transactions and results seems complete press Stop. </p>
        </div>

        <div style={{padding:"15px"}}>
          {errorText}

          <form style={{display:"flex", justifyContent:"center"}}>
          <label style={{display:"flex", alignItems:"center"}}>End Date:</label>

            {/* <div style={{display:"flex", flexDirection:"column"}}>
              <label>Year</label>
              <input
                type="number" 
                value={year}
                onChange={(e) => setYear(e.target.value)}
                className="dateForms"
                min={2000}
                max={2040}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
              <label>Month</label>
              <input
                type="number" 
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                className="dateForms"
                min={1}
                max={12}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
              <label>Day</label>
              <input
                type="number" 
                value={day}
                onChange={(e) => setDay(e.target.value)}
                className="dateForms"
                min={1}
                max={31}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
              <label>Hour</label>
              <input
                type="number" 
                value={hour}
                onChange={(e) => setHour(e.target.value)}
                className="dateForms"
                min={0}
                max={23}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
              <label>Minute</label>
              <input
                type="number" 
                value={minute}
                onChange={(e) => setMinute(e.target.value)}
                className="dateForms"
                min={0}
                max={59}
              />
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
              <label>Second</label>
              <input
                type="number" 
                value={second}
                onChange={(e) => setSecond(e.target.value)}
                className="dateForms"
                min={0}
                max={59}
              />
            </div> */}

            {/* </div> */}
          <input
            type="datetime-local" 
            value={endDateTime}
            onChange={(e) => {setEndDateTime(e.target.value); console.log(e.target.value)}}
          /> <b style={{marginLeft:"5px", fontSize:"20px"}} onClick={() => setEndDateTime('')}>x</b>
          </form>

          <form>
          <label>Address:
          <input
            type="text" 
            value={gAddress}
            onChange={(e) => setGAddress(e.target.value)}
          />
          </label>
          </form>

          <form>
          <label>Memo:
          <input
            type="text" 
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />
          </label>
          </form>
          { startTrigger === 0 && <button style={{width: "200px", marginTop:"10px"}}
            onClick={() => startFetch()}>Start</button>}

          { startTrigger > 0 && <button style={{width: "200px", marginTop:"10px"}}
            onClick={() => stopFetch()}>Stop</button>}
        
          <p className="status">Loading: <b>{loading.toString()}</b></p>
          <p className="status">All transactions fetched: <b>{isFullLoaded.toString()}</b></p>
          <p className="status">Current transactions page: <b>{emptyPageCount.toString()}</b></p>
          <p></p>
          <p className="status">Total addresses: <b>{txHistory.length}</b> records</p>
          <p className="status">Total received: <b>{txHistory.reduce((partialSum, item) => partialSum + Number(item.xlmAmount), 0)} XLM</b></p>
          {refundHistory.length > 0 && <p className="status">Total refunds: <b>{refundHistory.reduce((partialSum, item) => partialSum + Number(item.xlmAmount), 0)} XLM</b></p>}

          {/* const sum = [1, 2, 3].reduce((partialSum, a) => partialSum + a, 0); */}
        </div>

        <div>

        </div>
        
        {refundHistory.length > 0 && <div style={{display:"flex", flexDirection:"column", overflowY:"scroll", minHeight:"100px", height: "auto", width: "850px", background:"rgba(255,0,0,0.1)", padding:"15px", margin: "auto"}}>
          <h3 style={{margin:"0", padding:"0", marginBottom:"10px"}}>Late Refunds</h3>
          {refundHistory.map(item => {
            return <div key={item.id} style={{display:"flex", justifyContent:"space-between"}}>
              <div>{item.source_account}</div>
              <div>{item.created_at}</div>
              <div>{item.xlmAmount} XLM</div>
            </div>
          })}
        </div>}

        {txHistory.length > 0 && <div style={{display:"flex", flexDirection:"column", overflowY:"scroll", height: "600px", width: "850px", background:"rgba(255,255,255,0.1)", padding:"15px", margin: "auto"}}>
          <h3 style={{margin:"0", padding:"0", marginBottom:"10px"}}>Valid</h3>
          {txHistory.map(item => {
            return <div key={item.id} style={{display:"flex", justifyContent:"space-between"}}>
              <div>{item.source_account}</div>
              <div>{item.created_at}</div>
              <div>{item.xlmAmount} XLM</div>
            </div>
          })}
        </div>}
      </div>

    </div>
  );
}

export default App;
